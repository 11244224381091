import React from "react";
import Layout from "../components/layout";
import ProjetosBanner from "../images/ProjetosBanner.jpg";
import Arquitetonico from "../images/vetores/ArqPng.png";
import Eletrico from "../images/vetores/eletricopng.png";
import Estrutural from "../images/vetores/EstruturalPng.png";
import Hidrossanitario from "../images/vetores/HidrossanitarioPng.png";
import Preventivo from "../images/vetores/PreventivoPng.png";
import SEO from "../components/seo";

function Projetos() {
  return (
    <Layout>
      <SEO
        keywords={[`Golden`, `Sondagens`, `SPT`, `Construção`]}
        title="Projetos"
      />

      <section
        style={{
          height: "300px",
          backgroundImage: `linear-gradient(to right, hsla(0, 0%, 0%, 1), hsla(0, 0%, 0%, 0.3)), url(${ProjetosBanner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        <h1 className="font-bold text-white ml-8 mt-24 uppercase base-title-golden">
          Projetos
        </h1>
      </section>

      <div className="hidden lg:flex grid grid-cols-5 gap-12 font-bold items-baseline mx-auto mt-16">
        <div className="text-center">
          <img
            src={Arquitetonico}
            alt=""
            className="md:block w-1/2 img-zoom mx-auto"
          />
          <h3>Arquitetônico</h3>
        </div>
        <div className="text-center">
          <img
            src={Eletrico}
            alt=""
            className="md:block w-1/2 img-zoom mx-auto"
          />
          <h3>Elétrico</h3>
        </div>
        <div className="text-center">
          <img
            src={Estrutural}
            alt=""
            className="md:block w-1/2 img-zoom mx-auto"
          />
          <h3>Estrutural</h3>
        </div>
        <div className="text-center">
          <img
            src={Hidrossanitario}
            alt=""
            className="md:block w-1/2 img-zoom mx-auto"
          />
          <h3 className="mx-auto">Hidrossanitário</h3>
        </div>
        <div className="text-center">
          <img
            src={Preventivo}
            alt=""
            className="md:block w-1/2 img-zoom mx-auto"
          />
          <h3>Preventivo</h3>
        </div>
      </div>

      <div className="text-justify my-20 mx-8 md:mx-24 xl:mx-48">
        <h3>
          Elaboramos estudos e projetos para empreendimentos comerciais ou
          residenciais, que buscam a melhor solução em custo e desempenho
        </h3>
        <h3 className="my-12">
          Possuímos tecnologia de <b>protensão</b> em nossos projetos e
          execução, tecnologia essa que possibilita maiores vãos e espaços
          úteis, trazendo grandes benefícios, comodidade e possibilidades para
          nosso cliente.
        </h3>

        <div className="md:flex lg:ml-12 md:ml-8 items-center justify-center my-20 md:mx-10">
          <img src={Arquitetonico} alt="" className="w-1/3 md:w-1/12" />
          <div className="lg:w-4/5 lg:ml-32 md:ml-20">
            <div className="flex items-center">
              <h2 className="font-bold mb-5 base-title-golden">
                Arquitetônico
              </h2>
            </div>
            <p>
              O projeto arquitetônico é o precedente da obra, sua elaboração
              dá-se a partir da necessidade, possibilidade e perfil do cliente,
              aliando a aspectos técnicos que possibilitem otimização, qualidade
              e funcionalidade dos ambientes, além de visar a melhor utilização
              dos recursos energéticos e componentes construtivos a serem
              contemplados na obra, sem deixar de considerar os planos
              diretores, bem como, as legislações ambientais, municipais,
              estaduais e federais.
            </p>
          </div>
        </div>

        <div className="md:flex flex-row-reverse lg:mr-12 md:mr-8 items-center justify-center my-20">
          <img src={Eletrico} alt="" className="w-1/3 md:w-1/12" />
          <div className="lg:w-4/5 lg:mr-32 md:mr-20">
            <div className="flex items-center">
              <h2 className="font-bold mb-5 base-title-golden">Elétrico</h2>
            </div>
            <p>
              O projeto elétrico une todas as informações referentes a parte
              elétrica de uma instalação, máquinas ou equipamentos
              eletroeletrônicos, fornecendo dados que assegurem o melhor
              funcionamento de todos. É de extrema importância que sua
              elaboração seja feita de acordo com as normas existentes para que
              haja segurança e melhor rendimento nas instalações, além de
              favorecer a economia na utilização de materiais, execução e
              utilização dos mesmos.
            </p>
          </div>
        </div>

        <div className="md:flex lg:ml-12 md:ml-8 items-center justify-center my-20">
          <img src={Estrutural} alt="" className="w-1/3 md:w-1/12" />
          <div className="lg:w-4/5 lg:ml-32 md:ml-20">
            <div className="flex items-center">
              <h2 className="font-bold mb-5 base-title-golden">Estrutural</h2>
            </div>
            <p>
              O projeto estrutural fornece informações quanto ao dimensionamento
              das estruturas da obra, como vigas, pilares, lajes e outros, sendo
              de extrema importância na segurança, orçamento, resistência e
              durabilidade da construção. O cálculo estrutural correto evita a
              ocorrência de patologias estruturais, excesso de mão de obra,
              superdimensionamentos de materiais e maquinários, além de oferecer
              maior facilidade e segurança em futuras manutenções ou
              modificações na edificação, proporcionando maior longevidade da
              edificação.
            </p>
          </div>
        </div>

        <div className="md:flex flex-row-reverse lg:mr-12 md:mr-8 items-center justify-center my-20">
          <img src={Hidrossanitario} alt="" className="w-1/3 md:w-1/12" />
          <div className="lg:w-4/5 lg:mr-32 md:mr-20">
            <div className="flex items-center">
              <h2 className="font-bold mb-5 base-title-golden">
                Hidrossanitário
              </h2>
            </div>
            <p>
              Para o perfeito funcionamento de um empreendimento um bom projeto
              hidrossanitário é imprescindível. Ele consiste no desenvolvimento
              do projeto dos sistemas hidráulicos e sanitários de uma
              edificação. A compatibilização destes projetos forma o Projeto
              Hidrossanitário, que determina os pontos de entrada e saída da
              água e a correta destinação do esgoto.
            </p>
            <p>
              Um bom projeto Hidrossanitário pode incluir sistemas de tratamento
              de efluentes sanitários, sendo ideal para novas obras, sua
              elaboração levanta alternativas e realiza o planejamento das ações
              resultando na execução correta e na economia de recursos.
            </p>
          </div>
        </div>

        <div className="md:flex lg:ml-12 md:ml-8 items-center justify-center mt-20">
          <img src={Preventivo} alt="" className="w-1/3 md:w-1/12" />
          <div className="lg:w-4/5 lg:ml-32 md:ml-20">
            <div className="flex items-center">
              <h2 className="font-bold mb-5 base-title-golden">Preventivo</h2>
            </div>
            <p>
              O Plano de Prevenção e Proteção Contra Incêndios (PPCI) visa
              fornecer informações quanto as medidas de segurança necessárias
              para prevenção de incêndios presentes no local, assim como para
              disponibilizar meios para combatê-lo de maneira eficaz. O PPCI tem
              como finalidade, sob a ótica da construção civil, mitigar
              prejuízos em caso de incêndio, além de ser um item necessário para
              obtenção do habite-se para a grande maioria das edificações. Com o
              PPCI previnem-se fatalidades, focos de incêndio através do
              controle e mitigação de eventuais danos. O PPCI é exigido pelo
              Corpo de Bombeiros, independente do estado e localidade, visando
              segurança patrimonial e das pessoas.
            </p>
          </div>
        </div>
        <h3 className="mt-32">
          A Golden Tecnologia conta com uma equipe multidisciplinar para atender
          todas as necessidades pertinentes a projetos residenciais, comerciais
          e industriais, bem como, laudos e pareceres técnicos.
        </h3>
      </div>
    </Layout>
  );
}

export default Projetos;
